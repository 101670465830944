
@import "~@design";

.popup-showcase{
    background: none;

    .page{
        background: none;
    }

    .popup-modal-content{
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
    }

    .swiper-button-prev, .swiper-button-next{
        color:#fff;
    }

    .swiper-pagination {
        bottom: 0px;
        z-index: 100;
    }

    .swiper-pagination-bullet {
        margin: 0 2.5px;
        width: 8px;
        width: 8px;
        opacity: 0.7;
        &.swiper-pagination-bullet-active{
            background: $color-sec;
        }
    }

    &__img-wrap{
        min-height: 0;
        @include flex(center,center);

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .img-fallback{
            position: relative;
            width: 150px;
            height: 150px;
            svg{

            }
        }
    }

    &__foot{
        width: 100%;
        padding-top: 20px;
    }

    &__close-icon{
        width: 40px;
    }

}


